import { arrayOf, bool, func, shape, string } from 'prop-types';
import React, { useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import { Link } from 'react-router-dom';

import FormValidationErrors, {
  validate,
} from '../../components/generic/FormValidationErrors';
import { CheckoutFormDataShape } from '../../components/shapes';
import useApi from '../../hooks/useApi';
import useAnalytics from '../app/useAnalytics';
import { commentMaxLength } from './config';
import { BlueprintCategoryShape, CheckoutAlbumShape } from './shapes';
import { snakeify } from '../../util';

export const requestStates = {
  PENDING: 'PENDING',
  SUBMITTING: 'SUBMITTING',
  ERROR: 'ERROR',
  SUCCESS: 'SUCCESS',
};

const checkoutFormRules = blueprintCategory => [
  [({ firstName }) => !firstName, 'Vorname muss ausgefüllt werden'],
  [({ lastName }) => !lastName, 'Nachname muss ausgefüllt werden'],
  [({ email }) => !/\S+@\S+\.\S+/.test(email), 'E-Mail ist ungültig'],
  [({ address1 }) => !address1, 'Straße und Hausnummer muss ausgefüllt werden'],
  [({ zipCode }) => !zipCode, 'PLZ muss ausgefüllt werden'],
  [({ city }) => !city, 'Stadt muss ausgefüllt werden'],
  [({ countryCode }) => !countryCode, 'Land muss ausgefüllt werden'],
  [
    ({ phone }) => phone && !/^[\d\s]+$/.test(phone),
    'Telefonnummer darf nur Zahlen und Leerzeichen enthalten',
  ],
  [
    ({ comment }) => comment.length > commentMaxLength,
    'Kommentar darf max. 500 Zeichen lang sein',
  ],
  [
    ({ organization }) => blueprintCategory === 'business' && !organization,
    'Unternehmen muss ausgefüllt werden',
  ],
  [
    ({ organization }) => blueprintCategory === 'sports' && !organization,
    'Verein muss ausgefüllt werden',
  ],
];

function SubmitControls({
  disabled,
  formData,
  submit,
  goBack,
  blueprintCategory,
}) {
  const [printCheckConsent, setPrintCheckConsent] = useState(false);

  return (
    <>
      <Form.Group className="text-left">
        <Form.Check
          className="qa-print-check-consent"
          checked={printCheckConsent}
          onChange={() => setPrintCheckConsent(prevValue => !prevValue)}
          type="checkbox"
          id="check-consent"
          label="Für den Produktionsprozess gewähre ich Stickerstars Zugriff auf mein Album."
        />
      </Form.Group>
      <div className="checkout-form-submit">
        <FormValidationErrors
          rules={checkoutFormRules(blueprintCategory)}
          formData={formData}
        />
        <Button
          variant="primary"
          block
          className="qa-submit-form"
          disabled={disabled || !printCheckConsent}
          onClick={submit}
        >
          Kostenpflichtig bestellen
        </Button>
      </div>
      <Button variant="light" block className="mt-3" onClick={goBack}>
        Zurück zu den Bestelldetails
      </Button>
    </>
  );
}

SubmitControls.propTypes = {
  formData: CheckoutFormDataShape.isRequired,
  disabled: bool.isRequired,
  submit: func.isRequired,
  goBack: func.isRequired,
  blueprintCategory: BlueprintCategoryShape.isRequired,
};

function LoadingIndicator() {
  return (
    <div className="m-4">
      <Spinner animation="border" />
    </div>
  );
}

function SuccessMessage({ order }) {
  const { id, name } = order;
  return (
    <Alert variant="success" className="border">
      Vielen Dank, dein Auftrag (<Link to={`/orders/${id}`}>#{name}</Link>) wird
      bearbeitet! Du brauchst nichts weiter zu tun.
    </Alert>
  );
}

SuccessMessage.propTypes = {
  order: shape({ id: string, name: string }).isRequired,
};

function ErrorMessage({ onClick }) {
  return (
    <Alert variant="danger" className="border">
      Hoppla, das ging schief. Bitte versuche es noch einmal.
      <Button onClick={onClick} variant="link">
        OK
      </Button>
    </Alert>
  );
}

ErrorMessage.propTypes = { onClick: func.isRequired };

export default function CheckoutSubmitControl({
  checkoutStep,
  blueprintCategory,
  setCheckoutStep,
  formData,
  album,
  albumErrors,
}) {
  const [requestState, setRequestState] = useState(requestStates.PENDING);
  const [order, setOrder] = useState({});
  const api = useApi();
  const analytics = useAnalytics();
  const { errors } = validate(checkoutFormRules(blueprintCategory), formData);

  function handleSubmit() {
    try {
      if (errors.length > 0) {
        throw new Error();
      }
      setRequestState(requestStates.SUBMITTING);
      api
        .post(`/orders`, {
          ...snakeify(formData),
          album_id: album.id,
        })
        .then(({ data: { order: orderFromServer } }) => {
          const {
            album: { release_date: releaseDate },
            amount,
            amount_gross: amountGross,
          } = orderFromServer;
          setOrder(orderFromServer);
          setRequestState(requestStates.SUCCESS);
          analytics.track('Order Created', {
            albumId: album.id,
            releaseDate,
            amount,
            amountGross,
          });
        });
    } catch (err) {
      setRequestState(requestStates.ERROR);
    }
  }

  const createOrderMachine = {
    [requestStates.PENDING]: (
      <SubmitControls
        disabled={errors.length > 0 || albumErrors.length > 0}
        formData={formData}
        blueprintCategory={blueprintCategory}
        submit={handleSubmit}
        goBack={() => setCheckoutStep('product')}
      />
    ),
    [requestStates.SUBMITTING]: <LoadingIndicator />,
    [requestStates.SUCCESS]: <SuccessMessage order={order} />,
    [requestStates.ERROR]: (
      <ErrorMessage onClick={() => setRequestState(requestStates.PENDING)} />
    ),
  };

  return (
    <>
      {checkoutStep === 'preview' && (
        <div className="checkout-form-submit">
          <Button
            variant="primary"
            block
            className="qa-next-step-btn"
            onClick={() => setCheckoutStep('product')}
          >
            Nächster Schritt: Deine Paketinhalte
          </Button>
        </div>
      )}
      {checkoutStep === 'product' && (
        <div className="checkout-form-submit">
          <FormValidationErrors
            rules={[
              [
                ({ releaseDate }) => !releaseDate,
                'Tag des Events muss ausgefüllt werden',
              ],
            ]}
            formData={formData}
          />
          <Button
            variant="primary"
            block
            className="qa-next-step-btn"
            disabled={albumErrors.length > 0 || !formData.releaseDate}
            onClick={() => setCheckoutStep('contact')}
          >
            Nächster Schritt: Deine Daten
          </Button>
          <Button
            variant="light"
            block
            className="mt-3"
            onClick={() => setCheckoutStep('preview')}
          >
            Zurück zur Album-Vorschau
          </Button>
        </div>
      )}
      {checkoutStep === 'contact' && createOrderMachine[requestState]}
    </>
  );
}

CheckoutSubmitControl.propTypes = {
  checkoutStep: string.isRequired,
  blueprintCategory: string.isRequired,
  setCheckoutStep: func.isRequired,
  formData: CheckoutFormDataShape.isRequired,
  album: CheckoutAlbumShape.isRequired,
  albumErrors: arrayOf(string).isRequired,
};
