import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import Undoable from 'redux-live-undo';

import colorsAndFonts from './colorsAndFonts';
import images from './images';
import pexels from './pexels';
import workspace from './workspace';
import stickers from './stickers';
import controls from './controls';
import albums from './albums';
import blueprints from './blueprints';
import user from './user';
import tags from './tags';
import loading from './loading';
import selection from './selection';
import history from './history';
import notifications from './notifications';
import stickerUploads from './stickerUploads';
import smartGuides from './smartGuides';
import operations from './operations';
import viewport from './viewport';

export default combineReducers({
  router: routerReducer,
  colorsAndFonts,
  workspaceAndStickers: Undoable({
    workspace,
    stickers,
  }),
  controls,
  images,
  pexels,
  albums,
  blueprints,
  user,
  tags,
  loading,
  selection,
  history,
  notifications,
  stickerUploads,
  smartGuides,
  operations,
  viewport,
});
