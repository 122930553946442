import React from 'react';
import { List, Label } from 'semantic-ui-react';
import { connect } from 'react-redux';

import { getAlbumStatisticsForPreflight } from '../../selectors/legacy';
import { AlbumStatisticsForPreflightShape } from '../shapes';

const ExportPreflight = ({
  albumStatistics: { inlaySpreadsCount, spreadsNeeded, spine },
}) => (
  <List className="stats">
    <List.Item>
      <Label>
        Seiten (ohne Umschlag):
        <Label.Detail>
          {inlaySpreadsCount} Doppel, {inlaySpreadsCount * 2} Einzel
        </Label.Detail>
      </Label>
    </List.Item>

    <List.Item>
      <Label color={spreadsNeeded ? 'red' : null}>
        Fehlende Doppelseiten für Druck:
        <Label.Detail className="qa-spreads-needed">
          {spreadsNeeded}
        </Label.Detail>
      </Label>
    </List.Item>

    <List.Item>
      <Label>
        Rückenstärke:
        <Label.Detail>{spine}mm</Label.Detail>
      </Label>
    </List.Item>
  </List>
);

ExportPreflight.propTypes = {
  albumStatistics: AlbumStatisticsForPreflightShape.isRequired,
};

const mapStateToProps = state => ({
  albumStatistics: getAlbumStatisticsForPreflight(state),
});

export default connect(mapStateToProps)(ExportPreflight);
