import React from 'react';
import PropTypes from 'prop-types';
import ColorPicker from 'rc-color-picker';
import { Button } from 'semantic-ui-react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

import useScreenSize from '../../hooks/useScreenSize';
import { swatchTypeIndexes } from '../../constants';
import 'rc-color-picker/assets/index.css';

// todo move to component css

function ColorInput({ index, color, open, onClose, onOpen, onChange }) {
  const { isMobile } = useScreenSize();
  const placement = isMobile ? 'top' : 'bottom-start';

  const handleChange = ({ color: nextColor }) => {
    if (onChange) {
      onChange(nextColor);
    }
  };

  let icon;
  let enabled = true;

  if (index === swatchTypeIndexes.CUSTOM) {
    icon = 'edit';
  } else if (index <= 1) {
    icon = 'lock';
    enabled = false;
  } else {
    icon = 'linkify';
  }

  return (
    <div>
      {enabled ? (
        <OverlayTrigger
          trigger="click"
          placement={placement}
          show={open}
          overlay={
            <ColorPicker.Panel
              color={color}
              enableAlpha={false}
              onChange={handleChange}
              onClose={onClose}
            />
          }
          rootClose
          rootCloseEvent="mousedown"
          onToggle={onOpen}
          onExit={onClose}
        >
          <Button compact icon={icon} />
        </OverlayTrigger>
      ) : (
        <Button compact icon={icon} disabled />
      )}
    </div>
  );
}

ColorInput.defaultProps = {
  onChange: null,
  onOpen: null,
  onClose: null,
  color: null,
};

ColorInput.propTypes = {
  open: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  index: PropTypes.number.isRequired,
  color: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default ColorInput;
