import { func } from 'prop-types';
import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';

import useNotifications from '../../../hooks/useNotifications';
import { setAlbumData } from '../../../modules/albums';
import { selectAlbumData } from '../../../selectors/albums';
import useApi from '../../../hooks/useApi';
import Icon from '../../../components/Icon';
import useAnalytics from '../../app/useAnalytics';

function SettingsModal({ onHide }) {
  const dispatch = useDispatch();
  const albumData = useSelector(selectAlbumData);
  const albumPreviewUrlRef = useRef();

  const api = useApi();
  const analytics = useAnalytics();
  const { createError } = useNotifications();

  const {
    id: albumId,
    emailNotificationsEnabled,
    albumPreviewPath,
  } = albumData;

  const albumPreviewUrl = albumPreviewPath
    ? `${window.location.host}${albumPreviewPath}`
    : '';

  function handleCopy() {
    albumPreviewUrlRef.current.select();
    navigator.clipboard.writeText(albumPreviewUrlRef.current.value);
    analytics.track('AlbumPreview URL Copied');
  }

  function handleError(err) {
    createError(`Fehler beim Speichern: ${err.toString()}`);
  }

  async function handleEmailNotificationsChange(value) {
    const nextAlbumData = { ...albumData, emailNotificationsEnabled: value };
    try {
      dispatch(setAlbumData(nextAlbumData));
      await api.patch(`/albums/${albumId}`, {
        email_notifications_enabled: value,
      });
    } catch (err) {
      handleError(err);
    }
  }

  async function handleAlbumPreviewChange() {
    try {
      const {
        data: { album_preview_path: path },
      } = await api.patch(`/albums/${albumId}/rotate_album_preview_url_token`);
      dispatch(setAlbumData({ ...albumData, albumPreviewPath: path }));
    } catch (err) {
      handleError(err);
    }
  }

  return (
    <Modal show onHide={onHide} centered className="qa-settings-modal">
      <Modal.Header className="p-4 qa-settings-modal-header" closeButton>
        <Modal.Title>Einstellungen</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex flex-column px-4">
        <Form>
          <Form.Group controlId="emailNotificationsEnabled">
            <Form.Check
              type="checkbox"
              label="E-Mail-Benachrichtigungen"
              checked={emailNotificationsEnabled}
              onChange={() =>
                handleEmailNotificationsChange(!emailNotificationsEnabled)
              }
              className="qa-email-notification"
            />
            <small className="text-muted">
              Wir schicken dir z. B. eine Benachrichtigung, wenn über den
              Sticker-Link neue Sticker in dein Album geladen werden.
            </small>
          </Form.Group>
          <Form.Group controlId="albumPreviewEnabled">
            <Form.Check
              type="checkbox"
              label="Album-Vorschau"
              checked={albumPreviewUrl}
              onChange={handleAlbumPreviewChange}
              className="qa-toggle-album-preview"
            />
            <small className="text-muted">
              Aktiviere die Album-Vorschau, um einen teilbaren Link zu
              generieren. Der Link berechtigt nur zur Ansicht, nicht zur
              Bearbeitung. Achtung: Mit diesem Link kann jede:r dein Album
              einsehen. Teile ihn nur mit dir vertrauten Personen.
            </small>
            <InputGroup className="mt-2">
              <FormControl
                type="text"
                className="qa-album-preview-url"
                value={albumPreviewUrl}
                ref={albumPreviewUrlRef}
                readOnly
              />
              <InputGroup.Append>
                <Button variant="dark" onClick={handleCopy}>
                  <Icon name="clipboard" />
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </Form.Group>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

SettingsModal.propTypes = {
  onHide: func.isRequired,
};

export default SettingsModal;
