import customStickerpack from '../../assets/images/checkout/customStickerpack.jpg';
import designCheck from '../../assets/images/checkout/designCheck.jpg';
import premiumLamination from '../../assets/images/checkout/premiumLamination.jpg';
import premiumSupport from '../../assets/images/checkout/premiumSupport.jpg';

export default {
  custom_stickerpack: customStickerpack,
  design_check: designCheck,
  premium_support: premiumSupport,
  premium_lamination: premiumLamination,
};
