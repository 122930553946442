export default [
  {
    headline: 'Passe den Titel und Tag des Events an',
    text: 'Gib deinem Album einen Namen und lege den Tag des Events fest.',
    onClick: () => document.getElementById('albumTitleEditButton').click(),
    id: 'albumTitle',
  },
  {
    headline: 'Erstelle deinen ersten Sticker',
    text:
      'Klicke hier, um zur Stickerverwaltung zu gelangen – dort kannst du deine ersten Sticker erstellen.',
    link: 'stickers',
    id: 'sticker',
  },
  {
    headline: 'Passe die Kapitel an',
    text:
      'Die Kapitel strukturieren dein Album. Klicke hier, um zur Kapitelverwaltung zu gelangen.',
    link: 'stickers',
    id: 'section',
  },
  {
    headline: 'Bereite deinen Stickerlink vor',
    text:
      'Über den Stickerlink können deine Stickerstars ihre eigenen Fotos für Sticker hochladen.',
    link: 'stickerlink',
    id: 'stickerlink',
  },
  {
    headline: 'Platziere ein Hintergrundbild',
    text:
      'Ziehe ein Foto auf eine der Seiten, um ein Hintergrundfoto zu platzieren.',
    link: 'background',
    id: 'image',
  },
];
