import React, { memo } from 'react';
import { connect } from 'react-redux';
import { string, number, bool } from 'prop-types';

import {
  getWorkspace,
  getIsolation,
  getInteractiveParentIds,
  getSelectedElementIds,
} from '../../selectors/legacy';
import * as elements from './elements';
import {
  IdListShape,
  ElementTypeShape,
  SpreadPropsShape,
  WorkspaceShape,
  LocalTextsShape,
} from '../shapes';
import { getSelectInside } from '../../selectors/selection';
import { getSpacebarPressed, makeGetClipPath } from '../../selectors/controls';

export function NodeComponent(props) {
  const {
    id,
    nodeIndex,
    nodeSiblingCount,
    sectionId,
    type,
    childrenIds,
    isolation,
    spreadProps,
    workspace,
    preview,
    localTexts,
    scale,
    workspaceScale,
  } = props;
  // Sometimes, when a spread is deleted, for some reason its elements are still rendered (nested-delete-crash). In this case, `type` is not defined.
  // As a quick fix we exit here. In the long run the real reason for this behavior should be investigated...
  // TODO: Avoid workaround
  if (!type) {
    return null;
  }

  const reference = elements[type];

  // In isolation mode, draw isolated element on top of all other elements
  // TODO: sort drawing order to reflect pdf order restrictions: PDFs elements above all, text element above normal elements
  let sortedChildrenIds = childrenIds;
  if (isolation) {
    const isolationNodeIndex = childrenIds.indexOf(isolation);

    if (isolationNodeIndex !== -1) {
      sortedChildrenIds = [
        ...childrenIds.slice(0, isolationNodeIndex),
        ...childrenIds.slice(isolationNodeIndex + 1),
        childrenIds[isolationNodeIndex],
      ];
    }
  }

  // Resolve all the children and if the current node is a `Spread` inject its current props as `spreadProps`,
  // otherwise, use the received ones via props.
  const updatedSpreadProps =
    type === 'Spread'
      ? { id, nodeIndex, nodeSiblingCount, sectionId }
      : spreadProps;

  const children = sortedChildrenIds.map((childId, index) => (
    <ConnectedNode
      id={childId}
      key={childId}
      nodeIndex={index}
      workspace={workspace}
      nodeSiblingCount={sortedChildrenIds.length}
      spreadProps={updatedSpreadProps}
      preview={preview}
      localTexts={localTexts}
      workspaceScale={workspaceScale * scale}
    />
  ));

  return React.createElement(reference, props, children);
}

NodeComponent.defaultProps = {
  isolation: null,
  sectionId: null,
  spreadProps: null,
  workspace: null,
  preview: false,
  localTexts: null,
  nodeIndex: 0,
  nodeSiblingCount: 1,
  scale: 1,
  workspaceScale: 1,
};

NodeComponent.propTypes = {
  childrenIds: IdListShape.isRequired,
  isolation: string,
  id: string.isRequired,
  nodeIndex: number,
  nodeSiblingCount: number,
  sectionId: string,
  spreadProps: SpreadPropsShape,
  type: ElementTypeShape.isRequired,
  workspace: WorkspaceShape,
  preview: bool,
  localTexts: LocalTextsShape,
  scale: number,
  workspaceScale: number,
};

export function mapStateToProps(
  state,
  {
    id,
    workspace: ownWorkspace,
    preview,
    localTexts,
    spreadProps,
    workspaceScale,
  }
) {
  const getClipPath = makeGetClipPath();
  const isolation = getIsolation(state);
  const selectInside = getSelectInside(state);
  const selectedElementIds = getSelectedElementIds(state);
  const spacebarPressed = getSpacebarPressed(state);
  const interactiveParentIds = getInteractiveParentIds(state);
  const workspace = getWorkspace(state);
  const { colors } = state.colorsAndFonts;

  const nodes = ownWorkspace?.nodes || workspace.nodes;

  // This is part of a the nested-delete-crash workaround (see line #40 above)
  // TODO: Avoid workaround
  const { type, props, parent, children } = nodes[id] || {};

  // Prevent element manipulation/highlighting in sticker/preview mode and during pan
  const isStatic = preview || ownWorkspace || spacebarPressed;

  // Toggle interactive events of the BaseElement
  const isInteractive = !isStatic && interactiveParentIds.includes(parent);

  // Toggles clip-path handling within BaseElement and Image
  const isSelected = selectedElementIds.includes(id);

  // Highlight selected elements, if there is more than one selected element
  const isHighlighted = isInteractive && isSelected;

  const canSelectInside =
    isInteractive &&
    selectedElementIds.length === 1 &&
    ((type === 'Image' && (!!props.image || !!props.pexelsId)) ||
      (type === 'Text' && !props.symbol));

  const canIsolate =
    isInteractive && type === 'Group' && !isolation && !props.locked;

  const canMove = isInteractive && !selectInside;

  const canHighlight = isInteractive;

  return {
    id,
    type,
    ...props,
    childrenIds: children,

    // These props are not used by the NodeComponent but required by Spread, Text, BaseElement and Sticker components
    parentId: parent,
    colors,
    isolation,
    selectInside,
    isHighlighted,
    isInteractive,
    isSelected,
    canMove,
    canSelectInside,
    canIsolate,
    canHighlight,
    preview,
    localTexts,
    workspaceScale,
    clipPath: getClipPath(state, spreadProps),
  };
}

const ConnectedNode = memo(connect(mapStateToProps)(NodeComponent));

export default ConnectedNode;
