import React, { useEffect } from 'react';
import { batch, useDispatch } from 'react-redux';

import AlbumWizard from './index';
import { resolutions } from '../../constants';
import { ImageContext } from '../../components/ImageContext';
import { replaceColors, replaceFonts } from '../../modules/colorsAndFonts';
import { replaceImages } from '../../modules/images';
import { loadWorkspace } from '../../actions/workspace';
import useWizardAutosave from './useWizardAutosave';
import useWizardBlueprints from './useWizardBlueprints';

export function AlbumWizardContainer(props) {
  const dispatch = useDispatch();

  const {
    blueprints,
    filteredBlueprints,
    currentBlueprintData: { workspace, colors, sections, images, fonts },
    categoryFilter,
    setCategoryFilter,
    handleCategoryChange,
  } = useWizardBlueprints({ fetchOnLoad: true });

  const { showModal } = useWizardAutosave({
    setCategoryFilter,
  });

  function flushPropsToStore() {
    if (!workspace) {
      return;
    }

    batch(() => {
      dispatch(loadWorkspace(workspace));
      dispatch(replaceImages(images));
      dispatch(replaceColors(colors));
      dispatch(replaceFonts(fonts));
    });
  }

  useEffect(flushPropsToStore, [
    colors,
    images,
    sections,
    workspace,
    fonts,
    dispatch,
  ]);

  /**
   * Mounting any consumer component with `fetchOnLoad` will fetch `blueprints`
   * from the server, triggering a re-render once they are available (only one).
   * We use this effect to filter blueprints by the default category and cache +
   * fetch data for the first one. The user will then be able to apply any
   * wizard state saved in local storage (again, only once).
   */
  useEffect(() => {
    if (blueprints.length > 0) {
      handleCategoryChange(categoryFilter);
      showModal();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blueprints]);

  return (
    <ImageContext.Provider
      value={{
        resolution: resolutions.auto,
        cropPreview: true,
        showGuides: false,
        rendering: false,
        showStickers: true,
      }}
    >
      <AlbumWizard
        filteredBlueprints={filteredBlueprints}
        categoryFilter={categoryFilter}
        setCategoryFilter={setCategoryFilter}
        onCategoryChange={handleCategoryChange}
        {...props}
      />
    </ImageContext.Provider>
  );
}

export default AlbumWizardContainer;
