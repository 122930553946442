import React from 'react';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';

import Logo from '../../../../../assets/icons/logo.svg';
import Icon from '../../../../Icon';

function MobileWelcome() {
  return (
    <div className="py-5 d-flex align-items-center justify-content-around flex-column text-center">
      <img
        src={Logo}
        className="logo qa-stickerstars-logo my-4"
        alt="stickerstars logo"
        style={{ width: '100px' }}
      />
      <div className="w-90 mt-4">
        <h1>
          <span className="highlight-emoji" role="img" aria-label="Wave emoji">
            👋
          </span>
        </h1>
        <h3>Schön, dass du da bist.</h3>
        <p className="mt-4">
          In den folgenden 2 Schritten definierst du die Basis für dein
          individuelles Sammelalbum – diese kannst du im Anschluss jederzeit
          ändern.
        </p>
        <p className="text-muted">
          <small>
            Für die spätere Bearbeitung des Inhalts empfehlen wir dir die
            Nutzung eines Desktop-Rechners.
          </small>
        </p>
        <div>
          <Link to="/album/new/blueprints" className="qa-next-step-btn">
            <Button>
              Jetzt starten{' '}
              <Icon className="d-inline-block" name="arrow_right" />
            </Button>
          </Link>
        </div>
      </div>
      <div />
    </div>
  );
}

MobileWelcome.defaultProps = {};

MobileWelcome.propTypes = {};

export default MobileWelcome;
