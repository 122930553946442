import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import io from 'socket.io-client';
import { applyPatch, createPatch } from 'rfc6902';
import mapValues from 'lodash/mapValues';
import flatten from 'lodash/flatten';
import { useNetworkState } from 'react-use';

import { getStickers, getWorkspace } from '../../selectors/legacy';
import { getOperationActive } from '../../selectors/controls';
import useApi from '../../hooks/useApi';
import { loadWorkspace } from '../../actions/workspace';
import { MULTIPLAYER_URL } from '../../constants';
import { replaceStickers } from '../../actions/stickers';
import { replaceColors, replaceFonts } from '../../modules/colorsAndFonts';
import useNotifications from '../../hooks/useNotifications';
import {
  selectIsAlbumEditable,
  selectStickerTemplateSettings,
} from '../../selectors/albums';
import { setAlbumData } from '../../modules/albums';

/*
 * We squash consequent 'replace' operations for the same 'path'.
 * It is sufficient to only send the final value.
 */
export function getUpdates(queue) {
  const reverseQueue = [...queue].reverse();

  return queue.filter((operation, index) => {
    const { op: opType, path: opPath } = operation;
    if (opType !== 'replace') return true;

    const finalOperation = reverseQueue.find(
      ({ op, path }) => op === opType && path === opPath
    );
    const isFinalOperation = index === queue.lastIndexOf(finalOperation);
    return isFinalOperation;
  });
}

/**
 * Milliseconds that have to pass between
 * a store update and dispatching an API /commit request.
 */
const autosaveDebounce = 1800;

/**
 * When the queue holds 15 updates, we're flushing
 * it immediately.
 */
const maxQueueSize = 20;

const makeEmptyQueue = fields => mapValues(fields, () => []);

/**
 * Autosave hook.
 * @param {*} albumId id of album we're saving
 */
function useAutosave(albumId) {
  const [syncing, setSyncingState] = useState(false);
  const syncingRef = useRef(syncing);

  const { createError } = useNotifications();

  const setSyncing = value => {
    setSyncingState(value);
    syncingRef.current = value;
  };

  const socket = useRef();

  const [error, setError] = useState(undefined);

  const dispatch = useDispatch();

  const api = useApi();

  /**
   * Track the network status to avoid senseless
   * requests when no internet connection is available.
   */
  const { online } = useNetworkState();

  /**
   * Wait for the album to fully load
   * before observing store-updates.
   */
  const loaded = useSelector(state => state.albums.loaded);

  /**
   * We won't consider updates that are happening
   * within a single operation.
   */
  const operationActive = useSelector(getOperationActive);

  /**
   * We won't consider updates if the album is not editable
   * for the currently signed-in user.
   */
  const isAlbumEditable = useSelector(selectIsAlbumEditable);

  /**
   * Setting up the album fields that we want to
   * save auomatically. For each key, we'll need a snapshot
   * of its previous state and an effect that computes patches
   * when it gets updated.
   */
  const workspace = useSelector(getWorkspace);
  const stickers = useSelector(getStickers);
  const colors = useSelector(state => state.colorsAndFonts.colors);
  const fonts = useSelector(state => state.colorsAndFonts.fonts);
  const stickerTemplateSettings = useSelector(selectStickerTemplateSettings);

  const fields = useMemo(
    () => ({
      workspace,
      stickers,
      colors,
      fonts,
      sticker_template_settings: stickerTemplateSettings,
    }),
    [workspace, stickers, colors, fonts, stickerTemplateSettings]
  );

  const snapshots = useRef(mapValues(fields, () => null));

  /**
   * The queue holds our list of updates. We'll flush it to
   * the server every once in a while.
   */
  const [updates, setUpdates] = useState(makeEmptyQueue(fields));

  const resetUpdatesQueue = useCallback(
    () => setUpdates(makeEmptyQueue(fields)),
    [fields]
  );
  const totalQueueSize = flatten(Object.values(updates)).length;

  /**
   * Setting a ref to the updates value to be used inside
   * setTimeout — a setTimeout-call belongs to a single render
   * and "closes over" its state.
   */
  const updatesRef = useRef(updates);
  updatesRef.current = updates;

  /**
   * Actions that modify autosave-sensitive album fields
   * can be dispatched in `callback` and will be ignored
   * by the autosave hook.
   */
  const synchronize = useCallback(callback => {
    setSyncing(true);

    batch(() => {
      callback();
    });

    setSyncing(false);
  }, []);

  /**
   * Performing the PATCH request to our API. If something
   * fails, we'll set an error and fetch the last valid state
   * from the server.
   */
  const syncServer = useCallback(() => {
    /**
     * Do not send a request when device is offline,
     * we're currently syncing or the album is not editable
     * for the logged-in user.
     */
    if (
      online === false ||
      syncingRef.current ||
      socket.current?.disconnected ||
      !isAlbumEditable
    ) {
      return;
    }

    socket.current.emit('mutations', updatesRef.current);
    resetUpdatesQueue();
    setError(undefined);
  }, [online, resetUpdatesQueue, isAlbumEditable]);

  const syncClient = useCallback(async () => {
    const {
      data: { album },
    } = await api.get(`/albums/${albumId}`);

    synchronize(() => {
      dispatch(loadWorkspace(album.workspace));
      dispatch(replaceStickers(album.stickers));
      dispatch(replaceColors(album.colors));
      dispatch(replaceFonts(album.fonts));
      dispatch(setAlbumData({ stickerTemplateId: album.stickerTemplateId }));
    });
  }, [api, albumId, dispatch, synchronize]);

  /**
   * Schedule a debounced save operation
   * after every update.
   */
  const scheduledSave = useRef();

  const scheduleSave = useCallback(() => {
    if (scheduledSave.current) {
      clearTimeout(scheduledSave.current);
    }

    scheduledSave.current = setTimeout(syncServer, autosaveDebounce);
  }, [syncServer]);

  /**
   * Push an update to the queue. We're making sure
   * to keep the patch as small as possible by squashing
   * updates with the same path and operation.
   */
  const pushPatch = useCallback(
    (key, patch) => {
      if (syncingRef.current) {
        return;
      }

      const nextUpdates = {
        ...updates,
        [key]: getUpdates([...updates[key], ...patch]),
      };

      setUpdates(nextUpdates);
      updatesRef.current[key] = nextUpdates[key];

      scheduleSave();
    },
    [updates, scheduleSave]
  );

  /**
   * The main effect "observing" changes to
   * the workspace, stickers and colors store, computing
   * the diffs and storing them inside the queue.
   */
  useEffect(() => {
    if (!loaded || operationActive) {
      return;
    }

    Object.keys(fields).forEach(key => {
      const prevState = snapshots.current[key];

      // Other field was updated.
      if (fields[key] === prevState) {
        return;
      }

      const patch = createPatch(prevState, fields[key]);

      // something happened and it was not the initial album load
      if (patch.length > 0 && !!prevState) {
        pushPatch(key, patch);
      }

      // update the snapshot
      snapshots.current[key] = fields[key];
    });
  }, [fields, loaded, pushPatch, operationActive]);

  /**
   * Set up the websocket connection and event handlers.
   */
  useEffect(() => {
    async function setupSocket() {
      socket.current = io(MULTIPLAYER_URL, {
        query: {
          albumId,
        },
        extraHeaders: { token: await api.getAccessTokenSilently() },
        forceNew: true,
      });

      const refreshAuth = async () => {
        socket.current.io.opts.extraHeaders = {
          token: await api.getAccessTokenSilently(),
        };
      };

      socket.current.on('disconnect', async reason => {
        await refreshAuth();

        if (reason === 'io server disconnect') {
          socket.current.connect();
        }
      });

      socket.current.on('reconnect_attempt', async () => {
        await refreshAuth();
      });

      socket.current.on('connection_error', err => {
        createError(
          `Fehler bei Server-Verbindung, möglicherweise werden deine Änderungen nicht gespeichert: ${err.toString()}`
        );
      });

      socket.current.on('merge_error', () => {
        createError(
          'Dein Album konnte nicht gespeichert werden, wir werden es weiter versuchen.'
        );

        // fetch last health state
        syncClient();
      });

      // incoming album state mutations from other clients
      socket.current.on('mutations', mutations => {
        if (!loaded) {
          return;
        }

        const nextState = cloneDeep(snapshots.current);

        Object.keys(mutations).forEach(key => {
          applyPatch(nextState[key], mutations[key]);
        });

        synchronize(() => {
          dispatch(loadWorkspace(nextState.workspace));
          dispatch(replaceStickers(nextState.stickers));
          dispatch(replaceColors(nextState.colors));
          dispatch(replaceFonts(nextState.fonts));
          dispatch(
            setAlbumData({ stickerTemplateId: nextState.stickerTemplateId })
          );
        });
      });
    }

    setupSocket();

    // apparently `socket.current` might be undefined here in rare cases
    return () => socket.current?.disconnect();
  }, [albumId, api, createError, dispatch, synchronize, syncClient, loaded]);

  useEffect(() => {
    if (totalQueueSize > maxQueueSize) {
      if (scheduledSave.current) {
        clearTimeout(scheduledSave.current);
      }

      syncServer();
    }
  }, [syncServer, totalQueueSize, syncingRef]);

  return {
    synced: totalQueueSize === 0,
    syncing,
    error,
  };
}

export default useAutosave;
