import { bool, number, string } from 'prop-types';
import React, { memo, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';

import stickerTemplates from '../../../../assets/sticker';
import { defaultStickerTemplateId, resolutions } from '../../../../constants';
import { selectStickerTemplateSettings } from '../../../../selectors/albums';
import { getPlacedStickerNumbers } from '../../../../selectors/stickers';
import { ImageContext } from '../../../ImageContext';
import StickerDesign from './StickerDesign';
import StickerImage from './StickerImage';
import { rectsIntersect } from '../../../../util/geometry';
import { removeNodes } from '../../../../modules/workspace/base';

const Sticker = props => {
  const {
    id,
    doubleSticker,
    doubleStickerOffset,
    width,
    height,
    stickerLayout,
    cellLayout,
  } = props;
  const {
    showStickers,
    stickerRendering,
    resolution,
    mode: renderMode,
  } = useContext(ImageContext);
  const placedStickerNumbers = useSelector(getPlacedStickerNumbers);

  const stickerNumber = placedStickerNumbers[id];
  const offset = doubleSticker && stickerRendering ? doubleStickerOffset : 0;

  const templateId =
    useSelector(selectStickerTemplateSettings)?.id || defaultStickerTemplateId;

  const singleOrDouble = doubleSticker ? 'double' : 'single';

  const stickerTemplate = useMemo(
    () => stickerTemplates[templateId][singleOrDouble],
    [templateId, singleOrDouble]
  );

  /**
   * In album_softcover_inside + showStickers mode, we want to remove any
   * text nodes of the cell design that intersect with the sticker. This is because
   * the text nodes (e. g. number, name) would be rendered on top.
   */
  const cellLayoutWorkspace = useMemo(() => {
    const workspaceWithFallback =
      stickerTemplate.cell[cellLayout] || stickerTemplate.cell.default;

    if (renderMode === 'album_softcover_inside' && showStickers) {
      const { nodes } = workspaceWithFallback;

      const intersectingTextNodes = Object.values(nodes).filter(
        node =>
          node.type === 'Text' &&
          rectsIntersect({ x: 0, y: 0, width, height }, node.props)
      );

      return removeNodes(
        intersectingTextNodes.map(node => node.props.id),
        workspaceWithFallback
      );
    }

    return workspaceWithFallback;
  }, [
    renderMode,
    showStickers,
    cellLayout,
    stickerTemplate.cell,
    height,
    width,
  ]);

  // Clip paths applied in rendering include bleed, others do not.
  const clipPath = `url(#clip-${doubleSticker ? 'doublesticker' : 'sticker'}${
    stickerRendering && renderMode !== 'album_softcover_inside' ? '-render' : ''
  })`;

  return (
    <g
      transform={`translate(${offset},0)`}
      className={`sticker-root qa-sticker-root qa-sticker-root-${id}`}
      data-id={id}
    >
      {!(renderMode === 'stickers') && (
        <StickerDesign
          {...props}
          number={stickerNumber}
          workspace={cellLayoutWorkspace}
          clipPath="none"
          mask="url(#mask-stickercell)"
          className="qa-cell-layout"
        />
      )}
      {showStickers && (
        <>
          <StickerImage
            {...props}
            clipPath={clipPath}
            resolution={stickerRendering ? resolution : resolutions.medium}
          />
          <StickerDesign
            {...props}
            clipPath={clipPath}
            number={stickerNumber}
            workspace={
              stickerTemplate.sticker[stickerLayout] ||
              stickerTemplate.sticker.default
            }
            className="qa-sticker-layout"
          />
          {!stickerRendering && (
            <rect
              fill="none"
              stroke="#000"
              strokeOpacity={0.2}
              strokeWidth={0.5}
              style={{ vectorEffect: 'non-scaling-stroke' }}
              width={width}
              height={height}
            />
          )}
        </>
      )}
    </g>
  );
};

Sticker.defaultProps = {
  doubleSticker: false,
  doubleStickerOffset: 0,
  stickerLayout: 'default',
  cellLayout: 'default',
};

Sticker.propTypes = {
  x: number.isRequired,
  y: number.isRequired,
  width: number.isRequired,
  height: number.isRequired,
  id: string.isRequired,
  doubleSticker: bool,
  doubleStickerOffset: number,
  stickerLayout: string,
  cellLayout: string,
};

export default memo(Sticker);
