import React, { useRef } from 'react';
import { bool, func, node, string } from 'prop-types';
import Button from 'react-bootstrap/Button';

/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
function FileInputButton({
  name,
  accept,
  variant,
  className,
  children,
  onChange,
  multiple,
  disabled,
  size,
}) {
  const ref = useRef();

  return disabled ? (
    <Button variant={variant} className={className} disabled>
      {children}
    </Button>
  ) : (
    <label
      className={`${className} btn btn-${size} ${
        variant ? `btn-${variant}` : ''
      }`}
      htmlFor={name}
      onClick={() => ref.current?.click()}
      onKeyPress={() => ref.current?.click()}
    >
      {children}
      <input
        ref={ref}
        type="file"
        name={name}
        accept={accept}
        onChange={onChange}
        multiple={multiple}
        hidden
      />
    </label>
  );
}
/* eslint-enable jsx-a11y/no-noninteractive-element-interactions */

FileInputButton.defaultProps = {
  name: null,
  variant: null,
  className: '',
  multiple: false,
  disabled: false,
  size: 'md',
};

FileInputButton.propTypes = {
  name: string,
  variant: string,
  className: string,
  multiple: bool,
  disabled: bool,
  accept: string.isRequired,
  children: node.isRequired,
  onChange: func.isRequired,
  size: string,
};

export default FileInputButton;
