import { bool, string, func, number } from 'prop-types';
import React from 'react';
import Alert from 'react-bootstrap/Alert';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Link, useRouteMatch } from 'react-router-dom';
import merge from 'lodash/merge';

import onboardingSteps from './onboardingSteps';
import useQuery from '../../../hooks/useQuery';
import Icon from '../../Icon';
import useLoading, { commonTypes } from '../../../hooks/useLoading';
import useAnalytics from '../../../containers/app/useAnalytics';
import ProgressBar from '../../generic/ProgressBar';

const onboardStepStates = {
  done: 'done',
  active: 'active',
  todo: 'todo',
};

export function parseOnboardingStepState({ active, done }) {
  if (done) {
    return onboardStepStates.done;
  }

  if (active) {
    return onboardStepStates.active;
  }

  return onboardStepStates.todo;
}

/**
 * Depending on step props, we're either rendering
 * a) a click-handler (with custom onClick behaviour)
 * b) a DOM-Link with a route as `to`-prop
 */
export function OnboardingStep({
  headline,
  text,
  link,
  done,
  id,
  onClick,
  stepIdx,
  active,
}) {
  const analytics = useAnalytics();
  const currentState = parseOnboardingStepState({ active, done });

  function handleClick() {
    analytics.track('Onboarding Step Clicked', {
      onboardingStepKey: id,
    });

    // eslint-disable-next-line no-unused-expressions
    onClick?.();
  }

  return (
    <Link
      className={`onboarding-step qa-onboarding-step-${id}`}
      onClick={handleClick}
      to={link || '#'}
    >
      <Card
        className={`${currentState} qa-${currentState}-step mb-3`}
        bg="light"
      >
        <Card.Body className="p-3 position-relative">
          <div
            className={`onboarding-step-headline-icon position-absolute ${currentState}`}
          >
            {done ? (
              <Icon className="qa-onboarding-step-done" name="checkmark" />
            ) : (
              <span className="qa-onboarding-step-todo">{stepIdx + 1}</span>
            )}
          </div>
          <span className="onboarding-step-headline">{headline}</span>
          <div className="onboarding-step-text">{text}</div>
        </Card.Body>
      </Card>
    </Link>
  );
}
OnboardingStep.defaultProps = {
  onClick: null,
  link: '',
  done: false,
  active: false,
};

OnboardingStep.propTypes = {
  headline: string.isRequired,
  text: string.isRequired,
  link: string,
  id: string.isRequired,
  onClick: func,
  stepIdx: number.isRequired,
  done: bool,
  active: bool,
};

function OnboardingPanel() {
  const { isLoading } = useLoading(commonTypes.patchTitleReleaseDate);
  const {
    params: { albumId },
  } = useRouteMatch();

  const { data } = useQuery(`/albums/${albumId}/onboarding_state`, [isLoading]);

  const onboardingState = data?.onboarding_state || {};
  const {
    has_title_and_release_date: hasTitleAndReleaseDate,
    has_sticker: hasSticker,
    has_updated_section: hasUpdatedSection,
    has_updated_sticker_link_config: hasUpdatedStickerLinkConfig,
    has_image: hasImage,
  } = onboardingState;

  // The first unfulfilled step is always the active step
  const activeIdx = Object.values(onboardingState).findIndex(el => !el);
  const doneCount = Object.values(onboardingState).filter(Boolean).length;

  merge(onboardingSteps, [
    { done: hasTitleAndReleaseDate },
    { done: hasSticker },
    { done: hasUpdatedSection },
    { done: hasUpdatedStickerLinkConfig },
    { done: hasImage },
  ]);

  return (
    <div className="sidebar-panel onboarding-panel scrollable py-3">
      <Alert>
        <div className="d-flex pt-2">
          <ProgressBar
            value={doneCount / onboardingSteps.length}
            text={`${doneCount}/${onboardingSteps.length}`}
          />

          <div className="ml-3">
            <b className="text-dark">Erste Schritte</b>
            <br />
            Lerne den Designer kennen
          </div>
        </div>
      </Alert>
      <Row>
        {onboardingSteps.map((step, idx) => (
          <Col md={6} lg={4} key={step.id}>
            <OnboardingStep
              stepIdx={idx}
              active={activeIdx === idx}
              {...step}
            />
          </Col>
        ))}
      </Row>
    </div>
  );
}

export default OnboardingPanel;
