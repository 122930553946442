import { func } from 'prop-types';
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Alert from 'react-bootstrap/Alert';
import without from 'lodash/without';

import { OrderShape } from '../../../../components/shapes';
import useApi from '../../../../hooks/useApi';
import useNotifications from '../../../../hooks/useNotifications';

const signals = [
  'accepted',
  'rejected',
  'review_feedback',
  'review_completed',
  'production_started',
  'albums_shipped',
  'shipped',
];

function OrderEventForm({ order, onDispatch }) {
  const api = useApi();

  const { id, order_updates: pastUpdates } = order;
  const availableSignals = without(
    signals,
    ...pastUpdates.map(({ signal }) => signal)
  );
  const [event, setEvent] = useState({ signal: '' });
  const [submitting, setSubmitting] = useState(false);
  const { createError } = useNotifications();

  if (availableSignals.length < 1) {
    return (
      <Alert variant="dark">
        Es können keine weiteren Ereignisse für diese Bestellung erstellt
        werden.
      </Alert>
    );
  }

  async function handleSubmit(e) {
    e.preventDefault();

    setSubmitting(true);
    try {
      await api.post(`/orders/${id}/order_updates`, event);

      onDispatch();
    } catch (err) {
      createError(err.toString());
    }
    setSubmitting(false);
  }

  function handleSignalChange({ target: { value } }) {
    setEvent({ ...event, signal: value });
  }

  return (
    <div className="qa-order-event-form">
      <Form onSubmit={handleSubmit}>
        <Form.Group>
          <Form.Label>Signal</Form.Label>
          <FormControl
            as="select"
            onChange={handleSignalChange}
            value={event.signal}
            name="order_event_signal"
            className="qa-order-event-signal-select"
          >
            <option value="" disabled>
              Bitte wählen
            </option>
            {availableSignals.map(signal => (
              <option key={signal} value={signal}>
                {signal}
              </option>
            ))}
          </FormControl>
        </Form.Group>
        <Form.Group>
          <Button
            className="qa-order-event-submit-btn"
            disabled={submitting || event.signal === ''}
            type="submit"
          >
            Speichern
          </Button>
        </Form.Group>
      </Form>
    </div>
  );
}

OrderEventForm.propTypes = {
  order: OrderShape.isRequired,
  onDispatch: func.isRequired,
};

export default OrderEventForm;
