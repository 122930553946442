import React from 'react';
import { arrayOf, func, string } from 'prop-types';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { format } from 'date-fns';
import chunk from 'lodash/chunk';

import { CheckoutFormDataShape } from '../../components/shapes';
import { albumRanges, eventLabel, sliderLabel } from './config';
import FeatureCheckbox from './FeatureCheckbox';
import { AvailableFeatureShape } from './shapes';

function CheckoutProductForm({
  availableFeatures,
  blueprintCategory,
  onCheckoutAlbumCountChange,
  setFormData,
  formData,
  productsFromLineItems,
}) {
  const range = albumRanges[blueprintCategory];
  const { noAlbums, releaseDate, orderFeatures } = formData;

  // We use `checkout.line_items` from the server to check/uncheck the corresponding boxes.
  const isChecked = feature => {
    return productsFromLineItems.indexOf(feature.product) > -1;
  };

  const createHandler = key => ({ target: { value } }) =>
    setFormData(nextFormData => ({ ...nextFormData, [key]: value }));

  const handleOrderFeatureChange = product => {
    const index = orderFeatures.indexOf(product);

    // Remove or add product to array, depending on selection state
    const nextOrderFeatures =
      index > -1
        ? orderFeatures.filter(item => item !== product)
        : [...orderFeatures, product];

    setFormData({
      ...formData,
      orderFeatures: nextOrderFeatures,
    });
  };

  const availableFeatureChunks = chunk(availableFeatures, 2);

  return (
    <>
      <Form.Group className="d-flex flex-column mb-4 checkout-form__range p-4">
        <h6 className="mb-4 checkout-form__range__headline">
          {sliderLabel[blueprintCategory]}
        </h6>
        <Form.Control
          required
          className="qa-people-count-input mb-4"
          type="range"
          min={range.min}
          max={range.max}
          name="noAlbums"
          value={noAlbums}
          onChange={createHandler('noAlbums')}
          onMouseUp={onCheckoutAlbumCountChange}
          onTouchEnd={onCheckoutAlbumCountChange}
        />
        <Form.Label className="checkout-form__range__label qa-range-label text-center m-0">
          Ich benötige <b>{noAlbums} Alben</b>
        </Form.Label>
      </Form.Group>
      <Row className="my-4">
        <Col md={12}>
          <Form.Group>
            <Form.Label className="h6">
              <b>{eventLabel[blueprintCategory]}</b>
            </Form.Label>
            <Form.Control
              value={
                releaseDate ? format(new Date(releaseDate), 'yyyy-MM-dd') : ''
              }
              onChange={createHandler('releaseDate')}
              required
              type="date"
              name="releaseDate"
              className="checkout-form-release-date-input qa-album-release-date-input mt-2"
            />
          </Form.Group>
        </Col>
      </Row>
      <Form.Group>
        <Form.Label>
          <b>Wähle aus unseren Zusatzoptionen</b>
        </Form.Label>
        {availableFeatureChunks.map(features => (
          <Row key={features[0].product}>
            {features.map(feature => (
              <FeatureCheckbox
                key={feature.product}
                feature={feature}
                onChange={() => handleOrderFeatureChange(feature.product)}
                isChecked={isChecked(feature)}
                isDisabled={feature.mandatory}
              />
            ))}
          </Row>
        ))}
      </Form.Group>
    </>
  );
}

CheckoutProductForm.defaultProps = {
  availableFeatures: [],
  productsFromLineItems: [],
};

CheckoutProductForm.propTypes = {
  availableFeatures: arrayOf(AvailableFeatureShape),
  blueprintCategory: string.isRequired,
  onCheckoutAlbumCountChange: func.isRequired,
  setFormData: func.isRequired,
  formData: CheckoutFormDataShape.isRequired,
  productsFromLineItems: arrayOf(string),
};

export default CheckoutProductForm;
