import { shape, string } from 'prop-types';

export const AddressShape = shape({
  organization: string,
  first_name: string,
  last_name: string,
  address1: string,
  address2: string,
  city: string,
  zip_code: string,
  country_code: string,
  phone: string,
});

export default AddressShape;
