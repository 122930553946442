import BlueprintsPanel from '../../AlbumWizard/Sidebar/BlueprintsPanel/index.container';
import MobileWelcome from '../../AlbumWizard/Sidebar/MobileWelcome';
import StylesPanel from '../../AlbumWizard/Sidebar/StylesPanel';
import OrganizationForm from '../../AlbumWizard/Content/OrganizationForm';

export default [
  {
    key: 'welcome',
    title: 'Wähle eine Vorlage',
    content:
      'Unsere Grafiker haben tolle Vorlagen für dich gestaltet. Wähle deine Wunschvorlage und passe sie im nächsten Schritt nach deinem persönlichem Geschmack an.',
    renderContent: MobileWelcome,
    mobileOnly: true,
    ignoreInCount: true,
    renderFooter: () => null,
  },
  {
    key: 'blueprints',
    title: 'Wähle eine Vorlage',
    content:
      'Unsere Grafiker haben tolle Vorlagen für dich gestaltet. Wähle deine Wunschvorlage und passe sie im nächsten Schritt nach deinem persönlichem Geschmack an.',
    component: BlueprintsPanel,
  },
  {
    key: 'styles',
    title: 'Wähle einen Stil',
    content:
      'Wir haben eine Auswahl an modernen Stilen für dich zusammengestellt. Ändere gerne den Farbstil und die Schriftart nach deinen Wünschen.',
    component: StylesPanel,
  },
  {
    key: 'data',
    title: 'Fast geschafft!',
    content: "Ein paar letzte Infos, dann geht's los!",
    renderContent: () => null,
    renderPreviewContent: OrganizationForm,
    renderHeader: () => null,
    renderFooter: () => null,
    hideSidebar: true,
    ignoreInCount: true,
    businessOnly: true,
    withoutOrganizationOnly: true,
  },
];
