import React from 'react';
import { oneOf, string } from 'prop-types';
import { ReactSVG } from 'react-svg';

import autosize from '../assets/icons/autosize.svg';
import aligncenter from '../assets/icons/aligncenter.svg';
import alignjustify from '../assets/icons/alignjustify.svg';
import alignleft from '../assets/icons/alignleft.svg';
import alignright from '../assets/icons/alignright.svg';
import alwaysOnTop from '../assets/icons/always-on-top.svg';
import archive from '../assets/icons/archive.svg';
import arrowRight from '../assets/icons/arrow_right.svg';
import arrowRepeat from '../assets/icons/arrow_repeat.svg';
import arrows from '../assets/icons/arrows.svg';
import aspect from '../assets/icons/aspect.svg';
import ban from '../assets/icons/ban.svg';
import background from '../assets/icons/background.svg';
import bleed from '../assets/icons/bleed.svg';
import bold from '../assets/icons/bold.svg';
import box from '../assets/icons/box.svg';
import brush from '../assets/icons/brush.svg';
import brushFill from '../assets/icons/brush_fill.svg';
import caretDownFill from '../assets/icons/caret_down_fill.svg';
import checklist from '../assets/icons/checklist.svg';
import checkmark from '../assets/icons/checkmark.svg';
import chevronDown from '../assets/icons/chevron_down.svg';
import chevronLeft from '../assets/icons/chevron_left.svg';
import chevronRight from '../assets/icons/chevron_right.svg';
import chevronUp from '../assets/icons/chevron_up.svg';
import circle from '../assets/icons/circle.svg';
import clipboard from '../assets/icons/clipboard.svg';
import close from '../assets/icons/close.svg';
import closeThin from '../assets/icons/closeThin.svg';
import cloudCheck from '../assets/icons/cloud_check.svg';
import comment from '../assets/icons/comment.svg';
import commentCircle from '../assets/icons/comment_circle.svg';
import dots from '../assets/icons/dots.svg';
import download from '../assets/icons/download.svg';
import drag from '../assets/icons/drag.svg';
import duplicate from '../assets/icons/duplicate.svg';
import edit from '../assets/icons/edit.svg';
import editSquare from '../assets/icons/editSquare.svg';
import exclamationTriangle from '../assets/icons/exclamation_triangle.svg';
import expand from '../assets/icons/expand.svg';
import faceFit from '../assets/icons/face_fit.svg';
import fill from '../assets/icons/fill.svg';
import fullscreen from '../assets/icons/fullscreen.svg';
import goto from '../assets/icons/goto.svg';
import grid from '../assets/icons/grid.svg';
import group from '../assets/icons/group.svg';
import help from '../assets/icons/help.svg';
import hourglass from '../assets/icons/hourglass.svg';
import image from '../assets/icons/image.svg';
import images from '../assets/icons/images.svg';
import infoCircle from '../assets/icons/infoCircle.svg';
import italic from '../assets/icons/italic.svg';
import layouts from '../assets/icons/layouts.svg';
import line from '../assets/icons/line.svg';
import lineheight100 from '../assets/icons/lineheight100.svg';
import lineheight120 from '../assets/icons/lineheight120.svg';
import lineheight150 from '../assets/icons/lineheight150.svg';
import lineheight200 from '../assets/icons/lineheight200.svg';
import link from '../assets/icons/link.svg';
import list from '../assets/icons/list.svg';
import overlayLock from '../assets/icons/overlay_lock.svg';
import overlayExclamationTriangle from '../assets/icons/overlay_exclamation_triangle.svg';
import logo from '../assets/icons/logo.svg';
import minusSquare from '../assets/icons/minus_square.svg';
import opacity from '../assets/icons/opacity.svg';
import peelOff from '../assets/icons/peel_off.svg';
import plus from '../assets/icons/plus.svg';
import plusOutline from '../assets/icons/plus_outline.svg';
import plusSquare from '../assets/icons/plus_square.svg';
import printerFill from '../assets/icons/printer_fill.svg';
import raster from '../assets/icons/raster.svg';
import rectangle from '../assets/icons/rectangle.svg';
import redo from '../assets/icons/redo.svg';
import rotateLeft from '../assets/icons/rotate_left.svg';
import rotateRight from '../assets/icons/rotate_right.svg';
import rotateStep from '../assets/icons/rotate_step.svg';
import scale from '../assets/icons/scale.svg';
import search from '../assets/icons/search.svg';
import sendDown from '../assets/icons/send_down.svg';
import sendUp from '../assets/icons/send_up.svg';
import settings from '../assets/icons/settings.svg';
import sticker from '../assets/icons/sticker.svg';
import stickercell from '../assets/icons/stickercell.svg';
import stickerlink from '../assets/icons/stickerlink.svg';
import stock from '../assets/icons/stock.svg';
import stroke from '../assets/icons/stroke.svg';
import text from '../assets/icons/text.svg';
import textfile from '../assets/icons/textfile.svg';
import trash from '../assets/icons/trash.svg';
import underline from '../assets/icons/underline.svg';
import undo from '../assets/icons/undo.svg';
import ungroup from '../assets/icons/ungroup.svg';
import upload from '../assets/icons/upload.svg';
import uppercase from '../assets/icons/uppercase.svg';
import user from '../assets/icons/user.svg';
import calendar from '../assets/icons/calendar.svg';

const availableIcons = {
  aligncenter,
  alignjustify,
  alignleft,
  alignright,
  always_on_top: alwaysOnTop,
  archive,
  autosize,
  arrow_right: arrowRight,
  arrowRepeat,
  arrows,
  aspect,
  background,
  ban,
  bleed,
  bold,
  box,
  brush,
  brushFill,
  caret_down_fill: caretDownFill,
  checklist,
  checkmark,
  chevron_down: chevronDown,
  chevron_left: chevronLeft,
  chevron_right: chevronRight,
  chevron_up: chevronUp,
  circle,
  clipboard,
  close,
  closeThin,
  cloud_check: cloudCheck,
  comment,
  comment_circle: commentCircle,
  dots,
  download,
  drag,
  duplicate,
  edit,
  editSquare,
  exclamation_triangle: exclamationTriangle,
  expand,
  face_fit: faceFit,
  fill,
  fullscreen,
  goto,
  grid,
  group,
  help,
  hourglass,
  image,
  images,
  infoCircle,
  italic,
  layouts,
  line,
  lineheight100,
  lineheight120,
  lineheight150,
  lineheight200,
  link,
  list,
  overlay_lock: overlayLock,
  overlay_exclamation_triangle: overlayExclamationTriangle,
  logo,
  minus_square: minusSquare,
  opacity,
  peel_off: peelOff,
  plus_outline: plusOutline,
  plus_square: plusSquare,
  plus,
  printer_fill: printerFill,
  raster,
  rectangle,
  redo,
  rotate_left: rotateLeft,
  rotate_right: rotateRight,
  rotate_step: rotateStep,
  scale,
  search,
  send_down: sendDown,
  send_up: sendUp,
  settings,
  sticker,
  stickercell,
  stickerlink,
  stock,
  stroke,
  text,
  textfile,
  trash,
  underline,
  undo,
  ungroup,
  upload,
  uppercase,
  user,
  calendar,
};

function Icon({ name, className, ...rest }) {
  const iconSrc = availableIcons[name];

  if (!iconSrc) {
    return null;
  }

  return <ReactSVG className={`icon ${className}`} {...rest} src={iconSrc} />;
}

Icon.defaultProps = {
  className: '',
};

Icon.propTypes = {
  name: oneOf(Object.keys(availableIcons)).isRequired,
  className: string,
};

export default Icon;
