import { bool, func, node, number, string } from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';

import { colorValues } from '../../../../constants';
import { ImageContext } from '../../../ImageContext';
import MoveOperation from '../../../operations/MoveOperation';
import UnzoomedIcon from '../../UnzoomedIcon';
import ClipGhost from '../ClipGhost';
import useScreenSize from '../../../../hooks/useScreenSize';

const BaseElement = ({
  id,
  type,
  children,
  x,
  y,
  rotation,
  scale,
  width,
  height,
  isHighlighted,
  isInteractive,
  canMove,
  canHighlight,
  canSelectInside,
  canIsolate,
  clipPath,
  isSelected,
  showResolutionWarningIcon,
  workspaceScale,
  onDoubleClick,
}) => {
  const [hovered, setHovered] = useState(false);
  const { rendering } = useContext(ImageContext);
  const { isMobile } = useScreenSize();

  useEffect(() => setHovered(false), [isSelected]);

  return (
    <ClipGhost
      shouldRenderUnclipped={isSelected && type !== 'Text'}
      clipPath={clipPath}
      className={`${
        isInteractive ? 'element' : ''
      } container qa-element qa-${type}-node qa-element-${id}`}
      key={id}
      id={id}
      data-id={id}
      transform={`translate(${x},${y}) rotate(${rotation}) scale(${scale})`}
    >
      <g
        onMouseEnter={canHighlight ? () => setHovered(true) : undefined}
        onMouseLeave={() => setHovered(false)}
        onDoubleClick={onDoubleClick}
      >
        {children}
        {(isHighlighted || hovered) && (
          <rect
            className="highlight-box qa-element-highlight-box"
            width={width}
            height={height}
            stroke={colorValues.selection}
            fill="none"
            vectorEffect="non-scaling-stroke"
            strokeWidth="1"
          />
        )}
        {!rendering && canMove && !isMobile && (
          <MoveOperation
            id={id}
            width={width}
            height={height}
            canSelectInside={canSelectInside}
            canIsolate={canIsolate}
          />
        )}
        {showResolutionWarningIcon && (
          <UnzoomedIcon
            name="overlay_exclamation_triangle"
            className="text-warning qa-error-icon"
            tooltip="Niedrige Auflösung"
            width={width}
            height={height}
            scale={workspaceScale * scale}
          />
        )}
      </g>
    </ClipGhost>
  );
};

BaseElement.defaultProps = {
  x: 0,
  y: 0,
  rotation: 0,
  scale: 1,
  width: 0,
  height: 0,
  isSelected: false,
  clipPath: null,
  showResolutionWarningIcon: false,
  workspaceScale: 1,
  onDoubleClick: null,
};

BaseElement.propTypes = {
  id: string.isRequired,
  type: string.isRequired,
  children: node.isRequired,
  x: number,
  y: number,
  rotation: number,
  scale: number,
  width: number,
  height: number,
  isHighlighted: bool.isRequired,
  isInteractive: bool.isRequired,
  canMove: bool.isRequired,
  canHighlight: bool.isRequired,
  canSelectInside: bool.isRequired,
  canIsolate: bool.isRequired,
  isSelected: bool,
  clipPath: string,
  showResolutionWarningIcon: bool,
  workspaceScale: number,
  onDoubleClick: func,
};

export default BaseElement;
