import React from 'react';
import PropTypes from 'prop-types';
import {
  Icon,
  Button,
  Dropdown,
  Checkbox,
  Input,
  Form,
} from 'semantic-ui-react';
import { connect } from 'react-redux';
import qs from 'query-string';

import { renderModes, renderFormats, resolutions } from '../../constants';
import presets from '../../exportPresets';
import { getTargetSpreadIndex } from '../../selectors/legacy';
import { updateControls } from '../../modules/controls';
import ExportPreflight from './ExportPreflight';
import useApi from '../../hooks/useApi';

const CreateJobClass = ({
  changed,
  currentAlbum,
  jobSettings,
  onJobCreate,
  saving,
  targetSpreadIndex,
  updateControls, // eslint-disable-line no-shadow
}) => {
  const api = useApi();

  const { custom, mode, preset } = jobSettings;
  let { range } = jobSettings;

  if (preset === 'current_spread') {
    range = String(targetSpreadIndex + 1);
  }

  const startJob = () => {
    const params = {
      ...jobSettings,
      album_id: currentAlbum,
      range: String(range).replace(' ', ''),
      dpi: String(jobSettings.dpi).replace(' ', ''),
    };
    ['label', 'preset', 'custom'].forEach(key => delete params[key]);

    const data = {
      render_job: {
        params,
      },
    };

    api
      .post('/exports/render_jobs', data)
      .then(
        ({
          data: {
            render_job: renderJob,
            meta: { token },
          },
        }) => {
          const renderUrl = `${window.location.origin}/render?${qs.stringify({
            ...params,
            token,
          })}`;

          // eslint-disable-next-line no-console
          console.log(renderUrl);
          onJobCreate(renderJob);
        }
      )
      .catch(e => {
        window.alert(e.toString());
      });
  };

  const start = () => {
    startJob();
  };

  const setValue = (key, value) => {
    let { showStickers } = jobSettings;
    if (key === 'mode' && value === 'stickers') {
      showStickers = true;
    }
    updateControls({
      jobSettings: { ...jobSettings, showStickers, [key]: value },
    });
  };

  const setPreset = key => {
    const newJobSettings = { ...jobSettings, ...presets[key], preset: key };
    updateControls({ jobSettings: newJobSettings });
  };

  let rangeLabel;
  if (mode === 'spread') {
    rangeLabel = 'Doppelseiten';
  } else if (mode === 'stickers') {
    rangeLabel = 'Sticker';
  } else {
    rangeLabel = 'Seiten';
  }

  const startLabel = changed ? 'Speichern & Export starten' : 'Export starten';

  return (
    <Form className="create-job">
      <Form.Field inline>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label>Voreinstellung</label>
        <Dropdown
          className="qa-create-job-dd"
          value={jobSettings.preset}
          onChange={(_event, { value }) => setPreset(value)}
          options={Object.keys(presets).map(key => ({
            value: key,
            text: presets[key].label,
            key,
          }))}
          selection
          style={{ width: 180 }}
        />
      </Form.Field>

      {custom && (
        <>
          <Form.Field inline>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label>Mode</label>
            <Dropdown
              className="qa-create-job-form--mode-dd"
              value={jobSettings.mode}
              onChange={(_event, { value }) => setValue('mode', value)}
              options={Object.keys(renderModes).map(key => ({
                value: key,
                text: renderModes[key],
                key,
              }))}
              selection
              style={{ width: 180 }}
            />
          </Form.Field>
          <Form.Field inline>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label>Format</label>
            <Dropdown
              className="qa-create-job-form--format-dd"
              value={jobSettings.format}
              onChange={(_event, { value }) => setValue('format', value)}
              options={Object.keys(renderFormats).map(key => ({
                value: key,
                text: renderFormats[key],
                key,
              }))}
              selection
              style={{ width: 180 }}
            />
          </Form.Field>
          <Form.Field inline>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label>Auflösung</label>
            <Dropdown
              className="qa-create-job-form--resolution-dd"
              value={jobSettings.resolution}
              onChange={(_event, { value }) => setValue('resolution', value)}
              options={Object.keys(resolutions).map(key => ({
                value: key,
                text: resolutions[key],
                key,
              }))}
              selection
              style={{ width: 180 }}
            />
          </Form.Field>

          <Form.Field inline>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor="create-job-form[dpi]">DPI</label>
            <Input
              id="create-job-form[dpi]"
              onChange={(_event, { value }) => setValue('dpi', value)}
              value={jobSettings.dpi}
            />
          </Form.Field>

          <Form.Field inline disabled={mode === 'stickers'}>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor="create-job-form[preview-sticker]">
              Sticker anzeigen
            </label>
            <Checkbox
              id="create-job-form[preview-sticker]"
              checked={jobSettings.showStickers}
              onClick={(_event, { checked }) =>
                setValue('showStickers', checked)
              }
            />
          </Form.Field>

          <Form.Field inline disabled={mode !== 'album_softcover_inside'}>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor="create-job-form[include-cover]">
              Cover einschliessen
            </label>
            <Checkbox
              id="create-job-form[include-cover]"
              checked={jobSettings.includeCover}
              onClick={(_event, { checked }) =>
                setValue('includeCover', checked)
              }
            />
          </Form.Field>

          <Form.Field inline>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor="create-job-form[include-bleed]">
              Beschnittzugabe + Schnittmarken
            </label>
            <Checkbox
              id="create-job-form[include-bleed]"
              checked={jobSettings.includeBleed}
              onClick={(_event, { checked }) =>
                setValue('includeBleed', checked)
              }
            />
          </Form.Field>

          <Form.Field inline>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor="create-job-form[compress-pdf]">
              PDF komprimieren
            </label>
            <Checkbox
              id="create-job-form[compress-pdf]"
              checked={jobSettings.compress}
              onClick={(_event, { checked }) => setValue('compress', checked)}
            />
          </Form.Field>
        </>
      )}

      <Form.Field inline>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label htmlFor="create-job-form[range]">
          {rangeLabel} (z.B. 1-3,5)
        </label>
        <Input
          onChange={(_event, { value }) => setValue('range', value)}
          id="create-job-form[range]"
          value={range}
          type="text"
        />
      </Form.Field>

      {mode !== 'stickers' && (
        <Form.Field inline>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label>Preflight</label>
          <ExportPreflight />
        </Form.Field>
      )}

      <Form.Field inline>
        <Button
          className="qa-start-job-btn"
          disabled={saving}
          color="green"
          icon
          onClick={start}
        >
          <Icon name="play" />
          {startLabel}
        </Button>
      </Form.Field>
    </Form>
  );
};

CreateJobClass.defaultProps = {
  currentAlbum: null,
};

CreateJobClass.propTypes = {
  changed: PropTypes.bool.isRequired,
  currentAlbum: PropTypes.string,
  jobSettings: PropTypes.object.isRequired, // eslint-disable-line
  onJobCreate: PropTypes.func.isRequired,
  saving: PropTypes.bool.isRequired,
  targetSpreadIndex: PropTypes.number.isRequired,
  updateControls: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  changed: state.history.changed,
  currentAlbum: state.albums.currentAlbum,
  jobSettings: state.controls.jobSettings,
  saving: state.albums.saving,
  targetSpreadIndex: getTargetSpreadIndex(state),
});

const mapDispatchToProps = {
  updateControls,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateJobClass);
