import { createAction } from 'redux-actions';
import flow from 'lodash/flow';
import { batch } from 'react-redux';

import { dimensions, alignTypes, alignDirection } from '../constants';
import {
  getSelectedElements,
  getTargetNodeId,
  getSelectedElementIds,
  getLegacySpreads,
  getWorkspace,
  getSelectedSpreadIds,
  getTargetSpreadIndex,
  getSpreadIds,
  getSections,
  getSectionIds,
  getTargetSpreadId,
  getIsolation,
} from '../selectors/legacy';
import { getImage } from '../selectors/images';
import {
  elementToElementMatrix,
  getAxisAlignedBounds,
  getItemTransformInGroup,
} from '../util/geometry';
import { generateId } from '../util/index';
import { clearSelection, elementSelect } from '../modules/selection';
import { historyAnchor } from '../modules/history';
import { flatten, generateSpread } from '../util/generators';
import {
  uniquifyIds,
  resetStickerCellStickerIds,
  svgTransformationsForSvgImage,
} from '../util/spreads';
import { computeNativeImageSize } from '../util/images';
import {
  cloneElement,
  denormalizeElement,
  mergeUserId,
} from '../util/workspace';
import { getPexelImage } from '../selectors/pexels';
import { getNodeIdsByImageId } from '../selectors/workspace';
import { getCurrentBlueprintData } from '../selectors/blueprints';
import { getOperationParentId } from '../selectors/selection';
import { selectCurrentUserId } from '../selectors/user';

export const LOAD_WORKSPACE = 'workspace/LOAD_WORKSPACE';
export const REPLACE_SPREADS = 'workspace/REPLACE_SPREADS';
export const INSERT_ELEMENTS = 'workspace/INSERT_ELEMENTS';
export const UPDATE_ELEMENTS = 'workspace/UPDATE_ELEMENTS';
export const REPLACE_ELEMENTS = 'workspace/REPLACE_ELEMENTS';
export const DELETE_ELEMENTS = 'workspace/DELETE_ELEMENTS';
export const GROUP_ELEMENTS = 'workspace/GROUP_ELEMENTS';
export const UNGROUP_ELEMENTS = 'workspace/UNGROUP_ELEMENTS';
export const SWAP_ELEMENTS = 'workspace/SWAP_ELEMENTS';
export const REORDER_SECTIONS = 'workspace/REORDER_SECTIONS';
export const SEND_ELEMENTS_TO_FRONT = 'workspace/SEND_ELEMENTS_TO_FRONT';
export const SEND_ELEMENTS_TO_BACK = 'workspace/SEND_ELEMENTS_TO_BACK';
export const MOVE_ELEMENTS_TO_SPREAD = 'workspace/MOVE_ELEMENTS_TO_SPREAD';

/**
 * Init the complete reducer state (ie. when loading the crash dump)
 * @param {Object} initialState - The complete reducer state
 * @param {string} initialState.root  - The id of the root node
 * @param {Object} initialState.nodes - All nodes referenced by their ids. ( { <id>: { type, props, children} )
 */
export const loadWorkspace = initialState => ({
  type: LOAD_WORKSPACE,
  payload: { initialState },
  undoableIrreversibleCheckpoint: true,
});

/**
 * Insert one or more elements
 * @param {Object[]} elements - List of denormalized elements, all of them have the same parent
 * @param {string} parentId - Id of the parent element (for "Spread"-elements it must be "root")
 * @param {number} [index] - Index where the elements will be inserted (they will be appended at the end when `-1`)
 */
export const insertElementsCreator = createAction(
  INSERT_ELEMENTS,
  (elements, parentId, index = -1) => ({
    elements,
    parentId,
    index,
  })
);

/**
 * Adds the `currentUserId` to each element in `elements` recursively
 * before dispatching the `insertElementsCreator` action creator.
 */
export const insertElements = (nestedElements, parentId, index = -1) => (
  dispatch,
  getState
) => {
  const state = getState();
  const currentUserId = selectCurrentUserId(state);
  const nestedElementsWithUserIds = mergeUserId(nestedElements, currentUserId);

  dispatch(insertElementsCreator(nestedElementsWithUserIds, parentId, index));
};

/**
 * Update elements
 * @param {Object} propsDeltaMap - Props to update referenced by element id (e.g.: { ['id']: { x: 123, y :456 } })
 */
export const updateElements = createAction(UPDATE_ELEMENTS, propsDeltaMap => ({
  propsDeltaMap,
}));

/**
 * Replaces occurences of `imageId` by `nextImageId` in all workpspace nodes.
 */
export const updateElementsByImageId = (imageId, nextImageId) => (
  dispatch,
  getState
) => {
  const nodeIds = getNodeIdsByImageId(getState())[imageId] || [];
  nodeIds.forEach(nodeId => {
    dispatch(updateElements({ [nodeId]: { image: nextImageId } }));
  });
};

/**
 * Replace elements
 * @param {string[]} ids - Ids of the elements to be replaced
 * @param {Object} element - Denormalized element
 */
export const replaceElementsCreator = createAction(
  REPLACE_ELEMENTS,
  (ids, element) => ({
    ids,
    element,
  })
);

/**
 * Adds the `currentUserId` to `element` and all its children recursively
 * before dispatching the `replaceElementsCreator` action creator.
 */
export const replaceElements = (ids, nestedElement) => (dispatch, getState) => {
  const state = getState();
  const currentUserId = selectCurrentUserId(state);
  const [nestedElementWithUserIds] = mergeUserId(
    [nestedElement],
    currentUserId
  );

  dispatch(replaceElementsCreator(ids, nestedElementWithUserIds));
};

/**
 * Delete elements
 * @param {string[]} ids - Id of the elements to be deleted
 */
export const deleteElementsUnsafe = createAction(DELETE_ELEMENTS, ids => ({
  ids,
}));

/**
 * Delete elements and clear selection
 * @param {string[]} ids - Id of the elements to be deleted
 */
export const deleteElements = ids => dispatch =>
  batch(() => {
    dispatch(clearSelection());
    dispatch(deleteElementsUnsafe(ids));
  });

/**
 * Group elements
 * @param {string[]} ids - Id of the elements to be replaced
 * @param {Object} groupProps - The props of the new group element (including the `id`)
 * @param {Object} propsDeltaMap - Props to update referenced by element id (e.g.: { ['id']: { x: 123, y :456 } })
 */
export const groupElementsCreator = createAction(
  GROUP_ELEMENTS,
  (ids, groupProps, propsDeltaMap) => ({
    ids,
    groupProps,
    propsDeltaMap,
  })
);

export const groupElements = (ids, groupProps, propsDeltaMap) => (
  dispatch,
  getState
) => {
  const state = getState();
  const currentUserId = selectCurrentUserId(state);
  const propsWithUserId = { ...groupProps, userId: currentUserId };
  dispatch(groupElementsCreator(ids, propsWithUserId, propsDeltaMap));
};

/**
 * Ungroup elements
 * @param {string[]} ids - Id of the elements to ungroup (it is possible to ungroup different groups at the time)
 * @param {Object} propsDeltaMap - Props to update referenced by element id (e.g.: { ['id']: { x: 123, y :456 } })
 */
export const ungroupElements = createAction(
  UNGROUP_ELEMENTS,
  (ids, propsDeltaMap) => ({
    ids,
    propsDeltaMap,
  })
);

/**
 * Swap child elements
 * @param {string} parentId - Id of the parent element (for "Spread"-elements it must be "root")
 * @param {string} firstChildId - Id of the first child element to be swapped
 * @param {string} secondChildId - Id of the second child element to be swapped
 */
export const swapElements = createAction(
  SWAP_ELEMENTS,
  (parentId, firstChildId, secondChildId) => ({
    parentId,
    firstChildId,
    secondChildId,
  })
);

/**
 * Reorder sections
 * @param {string[]} sectionIds - Spread ids in the new order
 */
export const reorderSections = createAction(REORDER_SECTIONS, sectionIds => ({
  sectionIds,
}));

/**
 * Send elements to front
 * @param {string[]} ids - List of ids to be sent to front
 */
export const sendElementsToFront = createAction(
  SEND_ELEMENTS_TO_FRONT,
  ids => ({ ids })
);

/**
 * Send elements to back
 * @param {string[]} ids - List of ids to be sent to back
 */
export const sendElementsToBack = createAction(SEND_ELEMENTS_TO_BACK, ids => ({
  ids,
}));

/**
 * Move elements into a different spread
 * @param {string[]} ids - List of element ids to move
 * @param {string} newSpreadId - Id of the spread element where to move the elements
 * @param {Object} [propsDeltaMap] - Props to update referenced by element id. E.g.: { ['id']: { x: 123, y :456 } }
 */
export const moveElementsToSpread = createAction(
  MOVE_ELEMENTS_TO_SPREAD,
  (ids, newSpreadId, propsDeltaMap = {}) => ({
    ids,
    newSpreadId,
    propsDeltaMap,
  })
);

// Legacy mapping

export const updateSelectedElements = props => (dispatch, getState) => {
  const ids = getSelectedElementIds(getState());
  const propsDeltaMap = ids.reduce((acc, id) => {
    acc[id] = props;
    return acc;
  }, {});
  dispatch(updateElements(propsDeltaMap));
};

// High level actions

/**
 * Helper to apply a action to all selected elements
 */
const forSelection = action => (dispatch, getState) => {
  const state = getState();
  const { nodes } = getWorkspace(state);
  return getSelectedElementIds(state).forEach(id => {
    const element = denormalizeElement({ nodes, root: id });
    dispatch(action(element));
  });
};

export const alignElements = (align, axis) => (dispatch, getState) => {
  const extractElementsByIdsWithParentPosition = (
    children,
    ids,
    x = 0,
    y = 0
  ) =>
    children.map(element => {
      if (ids.indexOf(element.props.id) !== -1) {
        return { ...element, x, y };
      }
      if (Array.isArray(element.children)) {
        const ex = element.props.x !== undefined ? element.props.x : 0;
        const ey = element.props.y !== undefined ? element.props.y : 0;
        return extractElementsByIdsWithParentPosition(
          element.children,
          ids,
          x + ex,
          y + ey
        );
      }
      return null;
    });

  const state = getState();
  const ids = getSelectedElementIds(state);
  if (ids.length === 0) {
    return;
  }
  const {
    controls: { alignTo, alignUseMargin, pageMargins },
  } = state;

  const spreads = getLegacySpreads(state);
  const items = flatten(
    extractElementsByIdsWithParentPosition(spreads, ids)
  ).filter(item => item);

  const spreadId = getTargetSpreadId(state);
  const isolation = getIsolation(state);

  const selection = getAxisAlignedBounds(
    items,
    `.workspace [data-id='${spreadId}']`
  );

  let reference;
  switch (alignTo) {
    default:
    case alignTypes.selection:
      reference = selection;
      break;
    case alignTypes.spread:
      reference = {
        x: alignUseMargin ? pageMargins.left : 0,
        y: alignUseMargin ? pageMargins.top : 0,
        width:
          dimensions.pageWidth * 2 -
          (alignUseMargin ? pageMargins.right + pageMargins.left : 0),
        height:
          dimensions.pageHeight -
          (alignUseMargin ? pageMargins.top + pageMargins.bottom : 0),
      };
      break;
    case alignTypes.page:
      reference = {
        x:
          (alignUseMargin ? pageMargins.left : 0) +
          (selection.x < dimensions.pageWidth ? 0 : dimensions.pageWidth),
        y: alignUseMargin ? pageMargins.top : 0,
        width:
          dimensions.pageWidth -
          (alignUseMargin ? pageMargins.right + pageMargins.left : 0),
        height:
          dimensions.pageHeight -
          (alignUseMargin ? pageMargins.top + pageMargins.bottom : 0),
      };
      break;
  }

  const propsDeltaMap = {};

  items.forEach(item => {
    const { props } = item;
    const bound = getAxisAlignedBounds(
      [item],
      `.workspace [data-id='${spreadId}']`
    );
    let value;
    const size = axis === 'x' ? 'width' : 'height';
    switch (align) {
      default:
      case alignDirection.start:
        value =
          reference[axis] + (props[axis] - Math.min(bound[axis], props[axis]));
        break;
      case alignDirection.center:
        value =
          reference[axis] +
          reference[size] / 2 +
          (props[axis] - Math.min(bound[axis], props[axis])) -
          bound[size] / 2;
        break;
      case alignDirection.end:
        value =
          reference[axis] +
          reference[size] +
          (props[axis] - Math.min(bound[axis], props[axis])) -
          bound[size];
        break;
    }
    const delta = {};

    if (isolation) {
      const globalPoint = { x: 0, y: 0 };
      globalPoint[axis] = value;
      const localPoint = elementToElementMatrix(
        spreadId,
        isolation
      ).transformPoint(globalPoint);
      delta[axis] = localPoint[axis];
    } else {
      delta[axis] = value;
    }

    propsDeltaMap[item.props.id] = delta;
  });

  dispatch(updateElements(propsDeltaMap));
};

export const nudgeElements = (dx, dy) => (dispatch, getState) => {
  const propsDeltaMap = {};

  const state = getState();
  const elements = getSelectedElements(state);
  const {
    selection: { selectInside },
  } = state;

  elements.forEach(item => {
    if (selectInside) {
      const cx = item.props.cx + dx;
      const cy = item.props.cy + dy;
      propsDeltaMap[item.props.id] = { cx, cy };
    } else {
      const x = item.props.x + dx;
      const y = item.props.y + dy;
      propsDeltaMap[item.props.id] = { x, y };
    }
  });
  dispatch(updateElements(propsDeltaMap));
  dispatch(historyAnchor());
};

export const applyImage = imageId => (dispatch, getState) => {
  const state = getState();
  const selectedElements = getSelectedElements(state);
  const {
    controls: { autoFit },
  } = state;
  const imageObject = getImage(state, imageId);

  const propsDeltaMap = selectedElements.reduce((acc, element) => {
    if (element.type === 'Image') {
      let delta = { image: imageId, pexelsId: undefined };
      if (autoFit) {
        delta = {
          ...delta,
          ...svgTransformationsForSvgImage(imageObject, element, true),
        };
      }
      acc[element.props.id] = delta;
    }
    return acc;
  }, {});
  dispatch(updateElements(propsDeltaMap));
};

/**
 * Create spread
 * @param position: 0 = before active spread, 1 = after active spread
 */
const createSpreadPosition = position => (dispatch, getState) => {
  const state = getState();
  const spreadIds = getSelectedSpreadIds(state);
  const { nodes } = getWorkspace(state);
  const targetSpreadIndex = getTargetSpreadIndex(state);
  const spread = {
    type: 'Spread',
    props: {
      id: `ManualPage-${generateId()}`,
      fill: '#fff',
      sectionId: nodes[spreadIds[0]].props.sectionId,
    },
    children: [],
  };
  dispatch(insertElements([spread], 'root', targetSpreadIndex + position));
};

export const createSpreadBefore = () => createSpreadPosition(0);

export const createSpreadAfter = () => createSpreadPosition(1);

/**
 * Move spreads
 * @param {string} spreadId - Spread-id to move
 * @param direction: -1 = move up, +1 = move down
 */
export const moveSpread = (spreadId, direction) => (dispatch, getState) => {
  const state = getState();
  const spreadIds = getSpreadIds(state);
  const spreadIndex = spreadIds.findIndex(id => id === spreadId);
  const otherSpreadId = spreadIds[spreadIndex + direction];
  const { nodes } = getWorkspace(state);
  if (!otherSpreadId) {
    return;
  }
  // Double-check: Avoid moving spreads between sections
  const { parent: spreadParentId } = nodes[spreadId];
  const { parent: otherSpreadParentId } = nodes[otherSpreadId];
  if (spreadParentId !== otherSpreadParentId) {
    return;
  }
  dispatch(swapElements(spreadParentId, spreadId, otherSpreadId));
};

const moveSelectedSpreadItems = direction => (dispatch, getState) => {
  const [spreadId] = getSelectedSpreadIds(getState());
  dispatch(moveSpread(spreadId, direction));
};

export const moveSpreadItemsUp = () => moveSelectedSpreadItems(-1);

export const moveSpreadItemsDown = () => moveSelectedSpreadItems(+1);

/**
 * Duplicate Node
 * @param {string} id - Node-id to duplicate
 */
export const duplicateNode = id => (dispatch, getState) => {
  const state = getState();
  const { nodes } = getWorkspace(state);
  const { parent } = nodes[id];
  const { children } = nodes[parent];
  const nodeIndex = children.findIndex(childId => id === childId);
  const clonedElement = cloneElement(id, nodes);

  dispatch(insertElements([clonedElement], parent, nodeIndex + 1));
};

/**
 * Delete spreads
 */
export const deleteSpreadItems = () => (dispatch, getState) => {
  const state = getState();
  const selectedSpreadIds = getSelectedSpreadIds(state);
  if (selectedSpreadIds.length === 0) {
    return;
  }

  const spreadCount = getSpreadIds(state).length;
  if (spreadCount === 1) {
    // eslint-disable-next-line no-alert
    window.alert('Diese Seite kann nicht gelöscht werden.');
    return;
  }

  dispatch(deleteElements(selectedSpreadIds));
};

/**
 * Duplicate elements
 */
export const duplicateItems = () => (dispatch, getState) => {
  const state = getState();
  const { nodes } = getWorkspace(state);
  const selectedElements = getSelectedElements(state);
  const spreadId = getTargetNodeId(state);

  const denormalizeElementInWorkspace = element =>
    denormalizeElement({
      nodes,
      root: element.props.id,
    });

  const applyPositionalOffset = element => ({
    ...element,
    props: {
      ...element.props,
      x: element.props.x + 10,
      y: element.props.y + 10,
    },
  });

  const processElement = flow([
    denormalizeElementInWorkspace,
    applyPositionalOffset,
    uniquifyIds,
    resetStickerCellStickerIds,
  ]);

  const duplicatedElements = selectedElements.map(processElement);

  dispatch(insertElements(duplicatedElements, spreadId));
  dispatch(elementSelect(duplicatedElements.map(element => element.props.id)));
};

/**
 * Sending to foreground and background
 */
export const sendItemsFront = () => (dispatch, getState) => {
  const ids = getSelectedElementIds(getState());
  dispatch(sendElementsToFront(ids));
};

export const sendItemsBack = () => (dispatch, getState) => {
  const ids = getSelectedElementIds(getState());
  dispatch(sendElementsToBack(ids));
};

/**
 * Deleting
 */
export const deleteElementItems = () => (dispatch, getState) => {
  const selectedElementIds = getSelectedElementIds(getState());

  if (selectedElementIds.length === 0) return;

  dispatch(deleteElements(selectedElementIds));
};

/**
 * Grouping
 */
export const groupItems = () => (dispatch, getState) => {
  const state = getState();
  const ids = getSelectedElementIds(state);
  if (ids.length < 2) {
    return;
  }
  const items = getSelectedElements(state);
  const operationParentId = getOperationParentId(state);
  const groupArea = getAxisAlignedBounds(
    items,
    `.workspace [data-id='${operationParentId}']`
  );
  const id = generateId();
  const groupProps = {
    id,
    ...groupArea,
  };

  const propsDeltaMap = items.reduce((acc, item) => {
    acc[item.props.id] = {
      x: item.props.x - groupArea.x,
      y: item.props.y - groupArea.y,
    };
    return acc;
  }, {});

  dispatch(groupElements(ids, groupProps, propsDeltaMap));
  dispatch(elementSelect([id]));
};

export const ungroupItems = () => (dispatch, getState) => {
  const state = getState();
  const { nodes } = getWorkspace(state);
  const selectedGroupElements = getSelectedElements(state).filter(
    element => element.type === 'Group'
  );
  if (selectedGroupElements.length === 0) {
    return;
  }
  const propsDeltaMap = selectedGroupElements.reduce((parentAcc, element) => {
    return element.children.reduce((childAcc, childId) => {
      // eslint-disable-next-line no-param-reassign
      childAcc[childId] = getItemTransformInGroup(
        childId,
        nodes[element.props.id].parent
      );
      return childAcc;
    }, parentAcc);
  }, {});

  const ids = selectedGroupElements.map(element => element.props.id);
  dispatch(ungroupElements(ids, propsDeltaMap));
  dispatch(elementSelect(Object.keys(propsDeltaMap)));
};

/**
 * Content and frame fitting
 */
export const fitContent = (item, cover) => (dispatch, getState) => {
  const { image: imageId, pexelsId } = item.props;
  let imageObject;
  if (pexelsId) {
    imageObject = { details: getPexelImage(getState(), pexelsId) };
  } else {
    imageObject = getImage(getState(), imageId);
  }
  return dispatch(
    updateElements({
      [item.props.id]: svgTransformationsForSvgImage(imageObject, item, cover),
    })
  );
};

export const fitContentContain = () => dispatch =>
  dispatch(forSelection(item => fitContent(item, false)));

export const fitContentCover = () => dispatch =>
  dispatch(forSelection(item => fitContent(item, true)));

export const fitFrame = () => dispatch =>
  dispatch(
    // eslint-disable-next-line no-shadow
    forSelection(item => (dispatch, getState) => {
      const { props } = item;
      const { image: imageId, pexelsId } = props;
      let imageObject;
      if (pexelsId) {
        imageObject = { details: getPexelImage(getState(), pexelsId) };
      } else {
        imageObject = getImage(getState(), imageId);
      }
      let { width, height } = computeNativeImageSize(imageObject);
      let { rotation, cscale, crotation } = props;
      if (!rotation) rotation = 0;
      if (!cscale) cscale = 1;
      if (!crotation) crotation = 0;
      width *= cscale;
      height *= cscale;
      rotation += crotation;
      crotation = 0;
      const cx = 0;
      const cy = 0;
      dispatch(
        updateElements({
          [props.id]: {
            width,
            height,
            rotation,
            cx,
            cy,
            crotation,
          },
        })
      );
    })
  );

export const flipImage = () => dispatch =>
  dispatch(
    // eslint-disable-next-line no-shadow
    forSelection(item => dispatch => {
      dispatch(updateElements({ [item.props.id]: { flip: !item.props.flip } }));
    })
  );

export const createSection = props => (dispatch, getState) => {
  const sections = getSections(getState());

  /**
   * We will place new sections _before_ the last `static` section (which is usually a backcover).
   */
  const insertIndex = sections[sections.length - 1].static
    ? sections.length - 1
    : sections.length;
  const parentId = 'root';

  const section = {
    type: 'Section',
    props,
    children: [generateSpread()],
  };

  return dispatch(insertElements([section], parentId, insertIndex));
};

export const updateSection = (id, payload) => updateElements({ [id]: payload });

export const moveSection = (id, index) => (dispatch, getState) => {
  const sectionIds = getSectionIds(getState());
  const nextSectionIds = sectionIds.filter(_id => _id !== id);
  nextSectionIds.splice(index, 0, id);
  return dispatch(reorderSections(nextSectionIds));
};

export const deleteSection = id => deleteElements([id]);

export const resetStickerId = () => dispatch => {
  dispatch(
    forSelection(item => dispatchForSelection =>
      dispatchForSelection(
        updateElements({ [item.props.id]: { stickerId: null } })
      )
    )
  );
};

export const uploadWorkspaceImage = ({
  createWorkspaceImage,
  file,
}) => dispatch =>
  dispatch(
    forSelection(item => async selectionDispatch => {
      const image = await createWorkspaceImage(file);
      selectionDispatch(
        updateElements({
          [item.props.id]: { image: image.id, pexelsId: undefined },
        })
      );
    })
  );

export const applyBlueprintSpread = (blueprintSpreadId, targetSpreadId) => (
  dispatch,
  getState
) => {
  const currentBlueprintData = getCurrentBlueprintData(getState());
  if (!currentBlueprintData) {
    return;
  }

  const {
    workspace: { nodes },
  } = currentBlueprintData;
  const clonedSpreadElement = cloneElement(blueprintSpreadId, nodes);

  dispatch(replaceElements([targetSpreadId], clonedSpreadElement));
};

export const applyBlueprintSpreadToTargetSpread = blueprintSpreadId => (
  dispatch,
  getState
) => {
  const targetSpreadId = getTargetSpreadId(getState());
  dispatch(applyBlueprintSpread(blueprintSpreadId, targetSpreadId));
};
